
import WebbDivider from "../webb/webb-divider";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionDark from "../webb/head-section-dark";
import { useState } from "react";
import { useEffect } from "react";
import { AccountDetails, AccountSubscriptionDetails } from "../../services/srvc-users-realm";
import { BaseAccountDetails, BaseAccountBalance, CreateBaseAccount } from "../../services/srvc-accounts-realm";
import { UserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-user";
import WebbSpinText from "../webb/webb-spintext";

export default function UserOffers () {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();


  const [loading, setLoading]=useState(true);
  const [info, setInfo] = useState({})
  const [baseAc, setBaseAc] = useState({account:{nmbr:'', brch:'',mode:[]}})
  const [subs, setSubs] = useState([])
  const [balance,setBalance] = useState('-')
  const [text,setText] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [acnt,setAcnt] = useState(false)

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        const result = await BaseAccountBalance({
          data: {user: asset.user, type:"base-account"}, 
          user: asset.user,
        })
        // console.log(result)
        if(result.data!= false){setBalance(parseFloat(result.data.balance.number)/1000000)}
        
        const base = await BaseAccountDetails({
          data: {user: asset.user, type:"base-account"}, 
          user: asset.user,
        })
        // console.log(base)
        if(base.data==false){setText('Subscription Account Not Created. ')}
        if(base.data!=false){setBaseAc(base.data); setAcnt(true)}

        const subsciption = await AccountSubscriptionDetails({
          data: {user: asset.user}, 
          user: asset.user,
        })
       
        if(subsciption.data!= false)
        { 
          setSubs(subsciption.data.subs)
          setInfo(subsciption.data.meta)
        }
        // 
        

        setLoading(false);
      }
      fetchData()
    } else {}
  },[refresh]);

  const CreateSubscriptionAccount=async()=>{
    setLoading(true)
    var datx={
      data: {name:asset.name, mail:asset.mail,item: asset.user, type:"base-account", mobl:'', city:''}, 
      user: asset.user,
    }
   // console.log(datx)
    var res = await CreateBaseAccount(datx)
    console.log(res)
    setRefresh(!refresh)
    setLoading(false)
    
  }

  if (loading){ return ( <> <WebbSpinText /> </> ) }

  return (
    <>
      <HeaderSectionDark data={{name: 'Active Offer'}}/>
      <div className={`border rounded-wd p-3`}>
        <p className="text-color-tone m-0">Name</p>
        <p className="text-normal m-0">{info && info.name}</p>

        <WebbDividerSmall/>
        <p className="text-color-tone m-0">Validity</p>
        <p className="text-normal m-0">******</p>

      </div>
      <WebbDividerSmall />

      <HeaderSectionDark data={{name: 'Subscription Rate'}}/>
      <div className={`d-flex border rounded-wd py-3`}>
        <div className="ms-3">
          {
            subs.map(item=>{
              return(
                <p className="text-normal">{item.name}</p>
              )
            })
          }
        </div>
        <div className="ms-auto me-3 text-end">
        {
            subs.map(item=>{
              return(
                <p className="text-normal">{parseFloat(item.rate)/1000000}</p>
              )
            })
          }
        </div>
      </div>
      <WebbDividerSmall />

      
      <HeaderSectionDark data={{name: 'Subscription Account Details'}}/>
      <div className={`border rounded-wd p-3`}>
        <p className="text-color-tone m-0">Account Number</p>
        <p className="text-normal m-0">******</p>
        {/* <p className="text-normal m-0">{baseAc && baseAc.account.nmbr == ""? '******':baseAc.account.nmbr}</p> */}

        <WebbDividerSmall />
        <p className="text-color-tone m-0">IFSC Code</p>
        <p className="text-normal m-0">******</p>
        {/* <p className="text-normal m-0">{baseAc && baseAc.account.brch === "" ? '******' : baseAc.account.brch}</p> */}

        <WebbDividerSmall />
        <p className="text-color-tone m-0">Current Credit Balance</p>
        <p className="text-normal m-0">{balance}</p>

        <p className="text-color-tone m-0">Transfer Mode</p>
        {/* <p className="text-normal m-0">{baseAc && baseAc.account.mode.length>0? ( baseAc.account.mode.map(item=> {return(<p>{item}</p>)}))   :""}</p> */}
      </div>
      <WebbDividerSmall />
      <div className={acnt?'d-none':''}>
        <p className="text-small fw-bold text-danger">{text}</p>
        <button onClick={()=>CreateSubscriptionAccount()} className="btn p-0 btn-link text-small text-decoration-none">Create Subscription Account</button>
      </div>
      
      <WebbDividerMedium />

      <p className="text-danger">{balance<100? "You can contact us on sales@contrato.cc for subscription credits": ""}</p>

    </>
  )
}