// transfers 
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { numc, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { AccountBalance, BaseAccountBalance, BusinessAccountBalance, getMainAccountLists } from "../../services/srvc-accounts-realm";
import { TransfersCreate, WithdrawlCreate } from "../../services/srvc-transfers-realm";


export default function TransfersAccountDebitModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();

  const [form, setForm] = useState(false);

  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [debtAc, setDebtAc] = useState()

  
  const [text, setText] = useState ('');
  const [errtxt,setErrtxt] = useState('')

  const [chck, setCheck] = useState(false);
  const [acbl, setBalance] = useState('');
  const [baseblnc, setBaseblnc] = useState('')

  const [credAc, setCredAc]  = useState([])

  const [data, setData] = useState ({
    amnt:'',
    withdrawl:''
  
  })

  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const result = (await BusinessAccountBalance({ 
          data: { user: asset.user, type: "transfer-account" },
          user: asset.user
        })).data;
        // console.log(result)
        if(result!= false)
        {
          setBalance(result.balance.number)
          setDebtAc(result.account.number)
        }
        // handleChange("acid", result.enid);
       

        const baseacnt = (await BaseAccountBalance({ 
          data: { user: asset.user, type: "base-account" },
          user: asset.user,
        })).data;
        if(baseacnt!= false)
        {
          setBaseblnc(parseFloat(baseacnt.balance.number)/1000000)
        }
       

        setLoading(false)

      }
      fetchData()
    } else {}
  },[]);

  useEffect(()=>{
    setLoading(true)
    const fetchdata = async()=>{
      var datx= {
        data:{
          user:asset.user,
          type:'main-account'
        },
        webb:'',
        user:asset.user,

      }
      var data= await getMainAccountLists(datx)
      // console.log(data)
      if(data.status=="success")
      {
        setCredAc(data.data)
      }
      
    }
    fetchdata()
    setLoading(false)

  },[asset.user])


  // form validation
  useEffect( () => {
    setForm(false);
    if (data.amnt !=='' && !isNaN(data.amnt) && data.amnt !==0 && chck)
      setForm(true)
    
    if (data.amnt*1000000 > acbl) {
      setCheck(false);
      setText (`Withdraw Amount cannot be more than ${numc(acbl)}`);
    } else {
      setCheck(true);
      setText (``);
    }

  });

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading (true);
    var creditAccount
    //console.log(data.withdrawl)
    for(var i=0;i<credAc.length;i++)
    {
      if(credAc[i].item==data.withdrawl){creditAccount= credAc[i]}
    }
    // console.log(creditAccount)

    if(baseblnc>100)
    {
      if (form) {
        var datx = {
          debt:{
            name: asset.name,
            mail: asset.mail,
            user: asset.user,
            acnt: debtAc
          },
          cred:{
            name: creditAccount.user.name || '',
            mail: creditAccount.user.mail || '',
            user: Date.now().toString() || '',
            acnt: creditAccount.acnt.nmbr || '',
            ifsc: creditAccount.acnt.brch || '',
            address: creditAccount.user.city,
            mobile: creditAccount.user.mobl
          },
          amount: {number: (data.amnt*1000000).toString(), ticker: 'xxxx'},
          memo: 'account withdrawal',
          time: Date.now(),
          format: 'withdrawl',
          sort: 5
        }
        // console.log ((datx))
        // api call
        const result = await WithdrawlCreate({ data: datx, user: asset.user });
        console.log (result)

        if(result.data==false){
          setErrtxt(result.memo)
        }
       
        if (result.data!= false) {
          
          history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
        }
        setLoading (false);
      } 
      else {
        setText ('Please Enter all Details')
      }
    }

    else{
      setErrtxt('Error. Insufficient Balance, Please Recharge Your Subscription Account')
      
    }
    setLoading(false)
    
    
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }
  // return ( <> </> )
  
  return (
  <>
    {/* info */}
    <WebbDividerSmall/>

    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="">
        <div className="mb-3">
          <label className="form-label small">Choose Account <FormNeeded /></label>
          <select onChange={(e)=>handleChange("withdrawl",e.target.value)} className="form-select">
            <option>Choose</option>
            {
              credAc && credAc.length>0 && credAc.map(item=>{
                return(<option value={item.item} >{item.acnt.srvc}</option>)
              })
            }
          </select>

        </div>
        

        <div className="mb-3">
          <label className="form-label small">Enter Amount <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.amnt}
            onChange={({ target }) => {handleChange("amnt", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        {/* <div className="mb-3">
          <label className="form-label small">Enter Account Holder Name <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.name}
            onChange={({ target }) => {handleChange("name", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Enter Account Number <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.acnt}
            onChange={({ target }) => {handleChange("acnt", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Enter IFSC Code <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.ifsc}
            onChange={({ target }) => {handleChange("ifsc", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Enter Account Holder Mail <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.mail}
            onChange={({ target }) => {handleChange("mail", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Enter Account Holder Mobile <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.mobile}
            onChange={({ target }) => {handleChange("mobile", target.value); setText('');}}
            placeholder="">
          </input>
        </div>

        <div className="mb-3">
          <label className="form-label small">Enter Account Holder Address <FormNeeded /></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.address}
            onChange={({ target }) => {handleChange("address", target.value); setText('');}}
            placeholder="">
          </input>
        </div> */}

      </div>

      <div className="">
        <small className="">Available Balance: {numc(acbl)}</small>
      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      <div className="mb-3">
        <p className="small text-danger">{errtxt}<span className="text-white">{'.'}</span></p>
      </div>
      
      {/* actn */}
      <WebbDividerMedium />
      <div className="">
        <div className="d-grid">
          <button 
            className={`btn height-md rounded-none ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>

    </form>
    
    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}