// transaction detail view
import { numc } from '../../services/srvc-utilities'

import WebbDividerSmall from './webb-divider-sm';

const code = require('../../data/code-invoices.json').data

export default function InvoiceOverview(props) {

  const data = props.data;
  //console.log (data)
  return (
  <>
    <WebbDividerSmall />
    <div className="rounded-wd back-color-wite">

      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="caption-sm text-bold text-color-main m-0">{numc(data.amount.number)}</p>
          <p className="text-normal text-color-tone">{data.number}</p>
          <p className="m-0">Memo: {data.meta.memo}</p>
        </div>
        
        <div className="text-end">
          <div className=""  style={{width:'2rem', height:'2rem'}}>
            <p className={`text-color-${code.find(item=>parseInt(item.code) == data.status).colr} `}
              style={{fontSize:'2rem', lineHeight:'1rem'}}>
              <i className={code.find(item=>parseInt(item.code) == data.status).icon}></i>
            </p>
          </div>

        </div>

      </div>

      <div className='border-bottom'></div>
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">Invoice Date:</p>   
          <p className="m-0">Due Date:</p>   
        </div>
        <div className="text-end">
          {/* <p className="">{(new Date(data.date.stts)).toLocaleString().substring(0,10)}</p>    */}
          {/* <p className="m-0">{(new Date(data.date.ents)).toLocaleString().substring(0,10)}</p> */}
        </div>
      </div>

      <div className='border-bottom'></div>
      <div className="d-flex p-3">
        <div className="me-auto">   
          <p className="m-0">Status:</p>   
        </div>
        <div className="text-end">
          <p className="m-0">{code.find(item=>parseInt(item.code) == data.status).text}</p>
        </div>
      </div>

    </div>
    
    
    
    <div className={`p-3 bg-danger text-center text-white ${!data.chck && data.mode==="debit" ? '' : 'd-none'}`}>
      <WebbDividerSmall />
      {!data.check && data.mode==="debit" ? 'Low Funds! Please Add Funds via Dashboard' : ''}
    </div>
    
  </>
  )
}