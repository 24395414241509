//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";

import InvoicesSearchModule from "../content/invoices/invoices-search";
import InvoicesListModule from "../content/invoices/invoices-list";

import NewInvoiceModule from "../content/invoices/invoices-create";
import NewOrderModule from "../content/orders/orders-create";
import NewInvoiceRulesModule from "../content/orders/rules-create";

import InvoicesDownloadModule from "../content/invoices/invoices-download";


const listTabs = [
  {name: 'Recievables', code: 'inbound'},
  {name: 'Payables', code: 'outbound'}
]


export default function Invoices() {

  const [sort, setSort] = useState('inbound')
  const [search, setSearch] = useState({})

  const handleSearch = async(sort) => {
    // console.log('srch: ', sort)
    setSearch(sort);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong>Invoices</strong> </>,
      data: 
        <> 
          <HeaderSectionLite data={{name: 'Search Invoices'}}/>
          <InvoicesSearchModule  search={handleSearch}/>

          
        </>,
      foot: <> Today: </>
    }}
    main=   
      {{      
        head: <> Invoices List </>,
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`text-mini text-uppercase p-2 px-3 me-1 rounded-wd text-center cursor
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
            </div>
            
            <WebbDividerMedium />

            <InvoicesListModule  data={search || {} } />
          
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
            <NewInvoiceModule />
            <NewOrderModule />
            <InvoicesDownloadModule />
            <hr></hr>
            <NewInvoiceRulesModule />
          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}