// new user account created here
// user onboard - name, photo, mmid or emid, obnm = true

import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";

import { GetLocalUser } from "../../services/srvc-auth-user";
import { GetNewBusiness, SetNewBusiness } from "../../services/srvc-auth-user";

import { NewBusinessAccount } from "../../services/srvc-users-realm";

const businessFormList = (require("../../data/data-business-form.json")).data;
const businessDomainList = (require("../../data/data-business-domain.json")).data;

export default function OnboardBusinessNameModule() {
  
  const asset = GetLocalUser();
  const bsnx = GetNewBusiness();
  
  const history = useHistory();

  const [loading,setLoading]=useState(false);
  const [form,setForm]=useState(false);
  const [text, setText] = useState("");
  
  const [data, setData] = useState({
    name: '',
    form: 'bznx',
    domain: '',
    sort: '',
    mail: '',
    mobile:''
  });

  // useEffect for form validation
  useEffect( () => {
    setForm(false);
    if (data.name!=='' && data.form!=='' && data.domain!=='' && data.mail!=='') 
      setForm(true);
  },[data]);

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) {
      setLoading(true);

      const datx = { ...data, self: true, active: true, owner: true }
      
      const result = await NewBusinessAccount({data: {...datx, user: asset.user}, user: asset.user})
      console.log (result)
      if(result.data==false) {
        setLoading(false);
        setText('Error: Email is Already Registered !!!')
      } else {
        setLoading(false);
        history.push(`/auth/next`);
      }
      

      // if (bsnx.usid==='') response = await NewBusinessCreate( JSON.stringify(datx) )
      // else response = await SetBusinessInfo( JSON.stringify(datx) )

      // const result = await NewBusinessCreate(datx)
      
      // if (result.data) {
      //   console.log (result);
      //   SetNewBusiness({usid: result.data})
      //   // history.push(`/business/docs`);
      //   
        

      // }
      // else {alert('Error: Email already registered with another account !')}

      setLoading(false);
    }
    else {
      setText('Please enter Business Details')
    }
  }

  if (loading){ return ( <> <WebbLoader />  </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <p className="text-tone">Please Provide Business Details</p>

    {/* form */}
    <form onSubmit={handleSubmit}>
      <div className="">

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Business Name <FormNeeded/></label>
            <input type="text" 
              className="form-control height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value); setText('');}}
              placeholder="Enter Business Name">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Business Setup <FormNeeded/></label>
            <select 
              className="form-select height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.sort}
              onChange={({ target }) => handleChange("sort", target.value)}
            >
              <option value =''>Select Business Setup</option>
              {businessFormList.map((item,i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Industry Sector <FormNeeded/></label>
            <select 
              className="form-select height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.domain}
              onChange={({ target }) => handleChange("domain", target.value)}
            >
              <option value =''>Select Industry Sector</option>
              {businessDomainList.map((item,i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Business Email <FormNeeded/></label>
            <input type="email" 
              className="form-control height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.mail}
              onChange={({ target }) => {handleChange("mail", target.value); setText('');}}
              placeholder="Enter Business Email">
            </input>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-group">
            <label className="form-label small">Business Mobile <FormNeeded/></label>
            <input 
              className="form-control height-md rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.mobile}
              onChange={({ target }) => {handleChange("mobile", target.value); setText('');}}
              placeholder="Enter Business Mobile">
            </input>
          </div>
        </div>


      </div>

      {/* text */}
      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>

      {/* actn */}
      <WebbDividerMedium />      
      <div className="">
        <div className="d-grid">
          <button className={`btn height-md rounded-none ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

  </>
  )
}