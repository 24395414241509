//main
import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import HeaderSectionDark from "../content/webb/head-section-dark";
import WebbDateTime from "../content/webb/webb-datetime";

import UserInfoModule from "../content/home/user-infocard";
import AccountsBalanceModule from "../content/accounts/accounts-balance";
import UserTasksModule from "../content/home/user-tasks";

import UserContractsSummary from "../content/home/user-contracts";
import InvoicesStatusModule from "../content/invoices/invoices-status";
import DocumentsListWaitModule from "../content/documents/documents-list-wait";
import TransferListWaitModule from "../content/transfers/transfers-list-wait";
import InvoicesListWaitModule from "../content/invoices/invoices-list-wait";

const offr = 'https://img.freepik.com/free-vector/business-people-signing-online-contract-with-electronic-sign_74855-4379.jpg?size=338&ext=jpg'

export default function HomeUs() {

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-color-tone">Home</strong> </>,
      data: 
        <> 
          <UserInfoModule/>
          
          <WebbDivider />
          <WebbDividerSmall />
          <AccountsBalanceModule />
          
          <WebbDivider />
          <WebbDividerSmall />
          <UserTasksModule />
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: <> Summary and Actions </>,
        data: 
          <> 

          <HeaderSectionDark data={{name: 'Contracts Summary'}}/>
          <UserContractsSummary />          
 
          <WebbDividerSmall />
          <WebbDivider />

          <WebbDividerMedium />
          <HeaderSectionDark data={{name: 'Contracts Actions'}}/>
          <DocumentsListWaitModule/>
          <WebbDividerMedium />

          {/* <div className="border-bottom mb-3"></div>
          <WebbDividerMedium />
          <HeaderSectionDark data={{name: 'Invoices Summary'}}/>
          <InvoicesStatusModule /> */}

          {/* <div className="border-bottom mb-3"></div>
          <HeaderSectionDark data={{name: 'Transfers Actions'}}/>
          <TransferListWaitModule />
          <WebbDividerMedium /> */}


          <WebbDividerMedium />
          
        </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <>Activity </>,
        data: 
          <>
            {/* <HeaderSectionLite data={{name: 'Promotions'}}/>
            <img src={offr} alt='...' className="img-fluid border"></img>
            <WebbDividerSmall />
            <p>Open a New Account and Get first Contract Free</p> */}
            
          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}