// documents
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionDark from "../webb/head-section-dark";

import DocumentBasicInfo from "../webb/docx-basicinfo";
import DocumentMembersDelete from "../webb/docx-members-delete";
import DocumentMembersSet from "../webb/docx-members-set";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetSmartMembers, SmartGetInfo, SmartSetMembers } from "../../services/srvc-smart-realm";
import { GetUserContactsList } from "../../services/srvc-contacts-realm";

import NewContactIndividualModule from "./add-new-contact";
import { BusinessTeamMembers } from "../../services/srvc-users-realm";


export default function SmartsEditMembersModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(false);
  
  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(5)

  const [info, setInfo] = useState({});
  const [list, setList] = useState([]);
  
  const [mmbr, setMembers] = useState([]);
  const [add, setAdd] = useState(false)

  const [show, setShow] = useState(true)
  const [reload,setRelod] = useState(false)

  const [teammembers, setTeammembers] = useState([])
  const [tmbx, setTmbx] = useState()


  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        const datx = {
          data: { smart: id },
          user: asset.user,
        }

        var res = (await SmartGetInfo(JSON.stringify(datx))).data
        // basic info
        setInfo(res);
 
        // contact list
        const conx = (await GetUserContactsList({ 
          data: {
            user: asset.user,
            index: index, items: items,
            filters: {}
            },
          user: asset.user,  
        }))
        // console.log(conx)
        setList (conx.data.list)

        var team = (await BusinessTeamMembers({data:{user:asset.user, index:index, items: items,  filters: {}}, user:asset.user })).data
        if(team!= false)
        {
          // console.log(asset.mail)
          var filteredteam = team.list.filter((item) => item.mail !== asset.mail)
          // console.log(filteredteam)
          setTeammembers(filteredteam)
        }
        // console.log(team)

        if(conx.data.list.length==0 && team.list.length>0){setTmbx(true)}
        if(conx.data.list.length>0 ){setTmbx(false)}

        // current members
        var mmbx = (await GetSmartMembers(JSON.stringify(datx))).data;
        // console.log (mmbx)
        if(mmbx!=false)
        {
          setMembers(mmbx.map(item => {return {name: item.name, emid: item.mail, usid: item.user}}))

          var exists = mmbx.filter(function(item) {return item.user==asset.user})
          if(exists.length==0){setShow(false)}
        }

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user, reload]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (mmbr.length > 0 ) setForm(true);
  },[mmbr]);

  const SetMembers = async(sort) => {
    var mmbx
    if(tmbx == true)
    {
      mmbx = teammembers.find(item => item.user === sort)
    }

    if(tmbx==false)
    {
       mmbx = list.find(item => item.user === sort)
    }
    // console.log(mmbx)
    setMembers([...mmbr, {name: mmbx.name, emid: mmbx.mail, usid: mmbx.user}])
    // setMembers([...mmbr, {...mmbx, rank: mmbr.length+1}])

    if(mmbr.user==asset.user){setShow(false)}
  } 

  const ResetMembers = async(sort) => {
    // console.log (sort)
    var indx = mmbr.findIndex(item => item.usid === sort)
    // console.log (indx)
    if (indx>-1) {
      setMembers([...mmbr.slice(0,indx), ...mmbr.slice(indx+1)])
      // mmbr.forEach((item, i) => item.rank = i+1 )
    }
    
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  const handleChange = async(key, val) => {
    // setForm(false);
    
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // api call
    // console.log (mmbr)
    if (mmbr.length > 4) alert('Please add upto 4 members');
    else {
      var  person =[]
      for(var i=0;i<mmbr.length;i++)
      {
        var obj = {
          name:mmbr[i].name,
          mail: mmbr[i].emid,
          usid: mmbr[i].usid,
          rank: `${i+1}`
        }
        person.push(obj)
      }

      var payload = {
        data: {document: id, member: person},
        user: asset.user,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      // console.log(payload)
      const result = await SmartSetMembers (JSON.stringify(payload))
      // console.log (result)
      setLoading(false);
      history.push(`/${asset.form.substring(0,2)}/smart/view/${id}`)

    }
  
  }


  const AddMyself = async()=>{
    setMembers([...mmbr, {name: asset.name, emid: asset.mail, usid: asset.user}])
  }

  const setAddContact =async(item)=>{
    // console.log(item)
    setAdd(false)
    setRelod(true)


  }

  // console.log(list)

  if (loading){ return ( <> <WebbLoader /> </> ) }
  if (list.length===0 && teammembers.length==0) return (
    <>
      <WebbDividerMedium />
      <div className="p-3 rounded-wd back-color-wite text-center">
        <WebbDividerMedium />
        <p className="m-0">Please add Contacts via Dashboard</p>
        <WebbDividerMedium />
      </div>
    
    </>
  )
  
  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />
  
    <HeaderSectionDark data={{name: 'Approval / Signature Order'}}/>
    <DocumentMembersDelete data={mmbr} item={ResetMembers}/>
    <WebbDividerMedium />
  
    <div className="d-flex">
      <div className={`me-auto ${asset.form == 'bznx'?'d-none':''}`}>
        <button onClick={()=>AddMyself()} 
        className={`btn btn-sm back-color-wite border hitone p-2 px-3 rounded-wd text-color-next`}
        >Add Self</button>
      </div>
      <div class="form-check form-switch mt-2 text-end">
        <input value={tmbx} onChange={()=>setTmbx(!tmbx)} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  checked={tmbx}  />
        <label class="form-check-label" for="flexSwitchCheckChecked">{tmbx?'Teams':'Contact'}</label>
      </div>
    </div>
    <WebbDividerMedium />

    {/* <div className="d-flex mb-5 d-none">
      <div className="me-auto"><HeaderSectionLite data={{name: 'Add Signers'}}/></div>
      <div className="me-auto"><button onClick={()=>setAdd(!add)} 
        className={`btn btn-sm back-color-lite border hitone p-2 px-3 rounded-wd`}
        >New Contacts</button>
      </div>

      <div className="text-end">
      </div>  
        
    </div> */}
    <div className={`${tmbx?'':'d-none'}`}>
      <DocumentMembersSet data={teammembers} load={loading} item={SetMembers}/>
    </div>
    <div className={`${tmbx?'d-none':''}`}>
      <DocumentMembersSet data={list} load={loading} item={SetMembers}/>
    </div>

    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}


// {
//   add?
//   <>
//     <NewContactIndividualModule addContact = {setAddContact} />
//   </>:
//   <>
//    <DocumentMembersSet data={list} load={loading} item={SetMembers}/>
//    <form onSubmit={handleSubmit}>

//     <div className="">
//       <div className="d-grid">
//         <button className={`btn height-md btn-primary back-color-main rounded-none`}
//           disabled={!form}
//         ><small>Save & Continue</small>
//         </button>
//       </div>
//     </div>

//     </form>
//   </>
// }