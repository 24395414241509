//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import ContactsNewBulkModule from "../content/contacts/contacts-new-bulk";

export default function ContactsNewBulk() {

  return (
  <>
    <ContentFormatXX 
      head='Add Contacts (XLS)'
      link={`/${UserForm()}/contacts`}
      form= 'medium'
      data= {
        <>
          <ContactsNewBulkModule />
        </>
      } 
    />
  </>
  )
}