// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-user";
import { AccountBalance } from "../../services/srvc-accounts-realm";
import { useEffect } from "react";

export default function NewInvoiceModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const data = {
    name: 'New Invoice',
    link: `/${UserForm()}/invoices/new`,
    actv: true
  }

  return (
  <>
    <ActionCardSmall data={data}/>
  </>
  )
}