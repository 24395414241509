//init
// for OTP on mail - ready
import axios from 'axios'
import * as Realm from 'realm-web';
const app = new Realm.App({ id: process.env.REACT_APP_MNDB_RELM_CODE });


export const AuthCodeUser = async (id) => {
  //auth chat service
  const cred = Realm.Credentials.apiKey(process.env.REACT_APP_MNDB_AUTH_CODE);
  const user = await app.logIn(cred);
  return user
}

// Function Calls

// send and check passcode
export const CreatePasscode = async (item) => {
var payload={
    "data":{
        "user":item.email,
        "channel":"emid",
        "memo":"Email Verification"
    },
    "user":item.email
}
var result= await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-auth-mpyie/endpoint/code/create",payload)
 var {data}= result.data
//  console.log(data)
 return data

}

export const CheckPasscode = async (item) => {

  var payload={
    "data":{
        "user":item.email,
        "trxn":item.trxn,
        "code":item.otp
    },
    "user":item.email,
}
var result
await axios.post("https://ap-south-1.aws.data.mongodb-api.com/app/contrato-auth-mpyie/endpoint/code/check",payload)
.then((res=>{
   result=res.data.data
}))
.catch((err)=>{

  result=err.response.data.data
  // console.log(result)
  
})

//console.log(result)
return result
}