//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import AccountsLinkBaseModule from "../content/accounts/accounts-link-base";

export default function AccountsLinkBase() {

  return (
  <>
    <ContentFormatXX 
      head='Link Bank Account'
      link={`/${UserForm()}/accounts/link`}
      form= 'small'
      data= {
        <>
          <AccountsLinkBaseModule />
        </>
      } 
    />
  </>
  )
}