// documents
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import DocumentBasicInfo from "../webb/docx-basicinfo";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetDocumentInfo } from "../../services/srvc-smart-realm";
import { CreateTask, GetFormatData } from "../../services/srvc-form-realm";

export default function DocumentsNewFormDataModule() {

  const usxx = UserForm()
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const { id, frmx } = useParams();
  const history = useHistory();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(true);

  const [kycu,setKycu] = useState(false)
  const [kycb,setKycb] = useState(false)

  const [info, setInfo] = useState({}); // basic info
  const [data, setData] = useState([]); //format data
  const [viewdynamic,setViewdynamic]=useState(false)

  const [merge,setMerge] = useState(false)

  const [DynamicFieldsArr,setDynamicFieldsArr]= useState([])

  var dynamicFieldsArr=[]
  var AllkeysArr=[]
  var finalArr=[]
  var signerlist=[]

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // basic info
        setInfo( (await GetDocumentInfo(JSON.stringify({
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }))).data );

        var res=await GetFormatData({data: {fmid:frmx}})
        // console.log(res.data)
        setData(res.data) // form info

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.form !=='') setForm(true);
  },[data]);

  const HandleFormInput = async(key,value) => {

    // console.log(key, value)
    var indx=key
    var obj={ type:indx }
    obj[indx]=value
    // console.log(obj)
    dynamicFieldsArr.push(obj);
    setDynamicFieldsArr([...DynamicFieldsArr,...dynamicFieldsArr])
  }


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 

    // console.log(DynamicFieldsArr)
    DynamicFieldsArr.map(item=>{
      var keys= Object.keys(item)
      AllkeysArr.push(keys[1])
    })
 
    // console.log(AllkeysArr)
     
    var uniqueKeysArr=AllkeysArr.filter((x,i,a)=>a.indexOf(x)==i)
    // console.log(uniqueKeysArr)
    for(var i=0;i<uniqueKeysArr.length;i++) {
      var result=  DynamicFieldsArr.filter((x)=>x.type === uniqueKeysArr[i]);
      finalArr.push(result[result.length-1])
    }
    // console.log(finalArr)
     
    var obj1={}
    for(var i=0;i<finalArr.length;i++) {
      obj1={...obj1,...finalArr[i]}
    }
 
      // console.log(obj1)
    var listArr=[]
    listArr.push(obj1)
    
    var datx = {
      data:
        {
        "gdocid": data.nmbr,
        "document": id,
        "ekyc": merge,
        "kycu": kycu,
        "kycb": kycb,
        "list": listArr,
        "auto": false,
        "mode": "emid",
        "user": asset.name,
        },
      user: asset.user
    }

    //console.log(datx)
    
    // console.log(body)
    const res = await CreateTask(datx)
    // console.log (res)
    //window.alert("Congratulations!! Your Request for Template Preparation has been initiated")

    history.push(`/${asset.form.substring(0,2)}/smart/view/${info.smart_id}`) // check this route
    setLoading(false)

  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />
    <p className="text-bold">Please Enter Initial Document Data</p>
    
    {/* form-data */}
    <div className="p-3 bg-white" style={{height:"450px", overflowY:"scroll"}}> 
      {
      data!=null && data.vars.map((item,i) => {
        return(
          <div key={i}>
            <p className="mb-1">{item.name}</p>
            <input className='form-control height-md rounded-none'
              onChange={(e)=>HandleFormInput(item.code,e.target.value)} 
              type="text" 
              placeholder={'...'}/>
            <br/>
         </div>
        )
      })
      }
    </div>

    {/* KYC */}
    <WebbDividerMedium />
    <p className="text-bold">Collect User KYC</p>
    
    <div className="p-2 px-3 back-color-wite">
      <div className="d-flex cursor" onClick={() => setKycu(!kycu)}>
        <div className="me-auto mt-1">Individual KYC</div>
        <div className="text-end">
          <i className={`bi text-lead ${kycu ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
        </div>
      </div>
    </div>
    <div className="border-bottom border-light"></div>
    <div className="p-2 px-3 back-color-wite">
      <div className="d-flex cursor" onClick={() => setKycb(!kycb)}>
        <div className="me-auto mt-1">Business KYC</div>
        <div className="text-end">
          <i className={`bi text-lead ${kycb ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
        </div>
      </div>
    </div>


    <WebbDividerMedium />
    
    <div className={kycu || kycb ? '': 'd-none'}>

      <p className="text-bold">Document Options</p>

      <div className="border-bottom border-light"></div>
      <div className="p-2 px-3 back-color-wite">
        <div className="d-flex cursor" onClick={() => setMerge(!merge)}>
          <div className="me-auto mt-1">Update Document with KYC Data</div>
          <div className="text-end">
            <i className={`bi text-lead ${merge ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
          </div>
        </div>
      </div>

    </div>


    <WebbDividerMedium />

    {/* form */}
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none border-none`}
            disabled={!form}
          ><small>Select & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}