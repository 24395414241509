// create new transfer - debit / send
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { numc, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { GetUserContactsList } from "../../services/srvc-contacts-realm";
import { GetUserTeamList } from "../../services/srvc-contacts-realm";

import {  BaseAccountBalance, BusinessAccountBalance } from "../../services/srvc-accounts-realm";
import { TransfersCreate } from "../../services/srvc-transfers-realm";

const code = require('../../data/data-transfer-reasons.json').data;

export default function TransfersDebitModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');
  
  const [acbl, setBalance] = useState('');
  const [chck, setCheck] = useState(false);

  const [acnt, setAccounts] = useState([]);

  const [debtAcnt , setDebtAcnt] = useState()
  const [baseblnc,setBaseblnc] = useState()
  const [errtxt,setErrtxt] = useState('')

  const [data, setData] = useState({
    debt: asset.user, 
    cred: '', 
    amnt: '', 
    trsn: '', 
    memo: '', 
    time: (new Date()).toISOString().substring(0,10)
  });
  
  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        // get transfer account + account balance
        
        const acnt = (await BusinessAccountBalance({ 
          data: { user: asset.user, type: "transfer-account" },
          user: asset.user,
        })).data;
        // console.log(acnt)
        if(acnt!= false){
          setBalance(acnt.balance.number)
          setDebtAcnt(acnt.account.number)
        }
        // handleChange('dbac', acnt.account)
      

        //get base account + balance

        const baseacnt = (await BaseAccountBalance({ 
          data: { user: asset.user, type: "base-account" },
          user: asset.user,
        })).data;
        if(baseacnt!= false){
          setBaseblnc(parseFloat(baseacnt.balance.number)/1000000)
        }
        

        // contact list
        const conx = await GetUserContactsList({ 
          data: { user: asset.user, index: 1, items: 10, filters: {} },
          user: asset.user,
        })
        // console.log(conx)
        const tmbx = []
        //  (await GetUserTeamList({ 
        //   data: { sort: 'allx', actv: 'actv', usid: asset.usid },
        //   user: asset.usid
        // })).data

        // console.log (tmbx)
        setList ([...conx.data.list, ...tmbx])
        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);


  // form validation
  useEffect( () => {
    setForm(false);
    if (data.amnt !=='' && !isNaN(data.amnt) && data.amnt !==0 && chck
        && data.trsn !=='' && data.schd !=='' && data.cred !==''
      )
      setForm(true)
    
    if (data.amnt*1000000 > acbl) {
      setCheck(false);
      setText (`Transfer Amount cannot be more than ${acbl/1000000}`);
    } else {
      setCheck(true);
      setText (``);
    }

  });

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form) {

      setLoading (true);
      
      if(baseblnc > 100)
      {
        
        var datx = {
          cred:{
            name: list.find(item => item.user === data.cred).name,
            mail: list.find(item => item.user === data.cred).mail,
            user: data.cred,
            acnt: ""
          },
          debt:{
            name: asset.name,
            mail: asset.mail,
            user: asset.user,
            acnt: debtAcnt

          },
          amount: {number: (data.amnt*1000000).toString(), ticker: 'xxxx'},
          memo: data.trsn ,
          time: Date.now(),
          format: 'transfer',
          sort: 2
        }
      // get credit account number
        const acnt = (await BusinessAccountBalance({ 
          data: { user: datx.cred.user, type: "transfer-account" },
          user: datx.cred.user,
        })).data;

        if(acnt== false){setErrtxt("Error. Selected contact has not created business account for receiving payment. Please ask them to create business account for accepting payments ")}
        if(acnt!= false)
        {
          // console.log(acnt)
          datx.cred.acnt=acnt.account.number
          // console.log((datx));
          const result = await TransfersCreate ({
            data: datx, user: asset.user,
          });
          // console.log (result)
          if (result.data) {
            // console.log ('success')
            history.push(`/${asset.form.substring(0,2)}/transfers/submit`);
            
          }
          else {
            setText('Please enter transfer information');
          
          }
        }
        
      }
      else{
        setErrtxt("Error. Insufficient Balance, Please Recharge Your Subscription Account")
       
      }

      setLoading(false)


      }

      
    
    //history.push('/redirect-url')
  }

  if (loading) { return ( <> <WebbLoader /> </> ) }
  // return ( <> </> )
  
  if (list.length===0) return (
    <>
      <WebbDividerMedium />
      <div className="p-3 bg-white text-center">
        <WebbDividerMedium />
        <p className="m-0">Please add Contacts via Dashboard</p>
        <WebbDividerMedium />
      </div>
    
    </>
  )

  return (
  <>
    
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Enter Transfer Details and Submit'}}/>

    {/* input */}
    <form onSubmit={handleSubmit}>
      <div className="">
        
        <div className="form-group mb-3">
          <label className="form-label small">Select Contact <FormNeeded/></label>
          <select 
            className="form-select height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.cred}
            onChange={({ target }) => {
              handleChange("cred", target.value);
                  
              }}
          >
          <option value=''>Select Contact</option>
          {list.map((item,i) => (
            <option key={i} value={item.user}>{item.name}</option>
          ))}
          </select>
        </div>

        <div className={`form-group mb-3 ${data.cred ==='' ? 'd-none' : 'd-none'}`}>
          <label className="form-label small">Select Account <FormNeeded/></label>
          <select
            className="form-select height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.crac}
            onChange={({ target }) => {handleChange("crac", target.value);}}
          >
            <option value=''>Select Account</option>
            {acnt.map((item,i) => (
              <option key={i} value={item.enid}>{item.name}</option>
            ))}
          </select>
        </div>
        
        <div className={`form-group mb-3 ${data.cred ==='' ? 'd-none' : 'd-none'}`}>
          <label className="form-label small">Transfer Date <FormNeeded/></label>
          <input type="date" 
            className="form-control height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            disabled
            value={data.schd}
            onChange={({ target }) => {handleChange("schd", target.value); setText('');}}
            placeholder="">
          </input>
        </div>
        
        <div className={`form-group mb-2 ${data.cred ==='' ? 'd-none' : ''}`}>
          <label className="form-label small">Enter Amount <FormNeeded/></label>
          <input type="text" 
            className="form-control height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.amnt}
            onChange={({ target }) => {handleChange("amnt", target.value); setText('');}}
            placeholder="">
          </input>
        </div>
        
        <div className={`form-group mb-3 ${data.cred ==='' ? 'd-none' : ''}`}>
          <small className="">Available Balance: {numc(acbl)}</small>
          <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
        </div>

        <div className={`form-group mb-3 ${data.cred ==='' ? 'd-none' : ''}`}>
          <label className="form-label small">Transfer Reason <FormNeeded/></label>
          <select 
            className="form-select height-md bg-wite rounded-none"
            style ={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.trsn}
            onChange={({ target }) => {
              handleChange("trsn", target.value);
              setText('');
            }}
          >
          <option value=''>Select Transfer Reason</option>
          {code.filter(x=>x.user.includes(asset.form)).map((item,i) => (
            <option key={i} value={item.code}>{item.name}</option>
          ))}
          </select>
        </div>
        
        <div className={`form-group mb-3 ${data.cred ==='' ? 'd-none' : ''}`}>
          <label className="form-label small">Memo</label>
          <textarea type="text" 
            className="form-control bg-wite rounded-none"
            rows='2'
            style ={{fontSize:'0.9rem'}}
            value={data.memo}
            onChange={({ target }) => {handleChange("memo", target.value); setText('');}}
            placeholder="Enter Memo (Optional)">
          </textarea>
        </div>
        <p className="small text-danger">{errtxt}<span className="text-white">{'.'}</span></p>

      </div>


      {/* action */}
      <WebbDividerMedium />
      <div className={`${data.cred ==='' ? 'd-none' : ''}`}>
        <div className="d-grid">
          <button className={`btn height-md rounded-none ${ form ? 'btn-primary back-color-main' : 'btn-primary back-color-main'}`}
            disabled={!form}
          ><small>Submit</small>
          </button>
        </div>
      </div>
      
    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}