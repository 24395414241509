// accounts
import * as Realm from 'realm-web';

import axios from "axios";

const acnt = new Realm.App({ id: process.env.REACT_APP_MNDB_RELM_TRXN });

var base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-trxn-ingio/endpoint"
// var base = "https://ap-south-1.aws.data.mongodb-api.com/app/decentro-main-app-qylmm/endpoint"

// Function Calls
export const AuthTransactionUser = async (id) => {
  //auth transfer service
  const cred = Realm.Credentials.apiKey(process.env.REACT_APP_MNDB_AUTH_TRXN);
  const user = await acnt.logIn(cred);
  return user
}

// read functions
export const BusinessAccountBalance = async (item) => {
  
  const basx = base + '/account/balance';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }
}


export const BaseAccountBalance = async (item) => {
  
  const basx = base + '/account/balance';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }
}



export const BaseAccountDetails = async (item) => {
  
  const basx = base + '/account/detail';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
}


export const BusinessAccountDetails = async (item) => {
  
  const basx = base + '/account/detail';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
}


export const CreateBaseAccount = async (item) => {

  const basx = base + '/account/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }
}

export const CreateBusinessAccount = async (item) => {

  const basx = base + '/account/create/va';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data}=(error.response.data);
    console.log(data.message)
    return {data: false, memo:data.message}
  }
}

export const BankAccountLink = async (item) => {

  const basx = base + '/account/link/bank';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data}=(error.response);
    // console.log(error.response)
    return {data: data.data, status:data.status, memo:data.message}
  }
}

export const getMainAccountLists = async (item) => {

  const basx = base + '/account/list/main';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data}=(error.response);
    // console.log(error.response)
    return {data: data.data, status:data.status, memo:data.message}
  }
}

























export const GetBaseAccount = async (item) => {

  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetBaseAccount(JSON.stringify({
    ...item,
    webb: process.env.REACT_APP_WEBB_SITE_NMBR
  }))
  return JSON.parse(result);
}

export const GetAccountBalance = async (item) => {
  
  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetAccountBalance(JSON.stringify({
    ...item,
    webb: process.env.REACT_APP_WEBB_SITE_NMBR
  }))
  return JSON.parse(result);
}

export const GetUserAccountsList = async (item) => {
  
  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetUserAccountsList(JSON.stringify({
    ...item,
    webb: process.env.REACT_APP_WEBB_SITE_NMBR
  }));
  return JSON.parse(result);
}



// contacts
export const GetContactAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntGetUserAccountsList(item)
  return (result);
}

export const SetContactAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntCreateBaseAccount(item)
  return (result);
}

export const RemoveContactAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntRemoveContactAccounts(item)
  return (result);
}


// account linking




export const CheckUPIAccount = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntCheckUPIAccount(item)
  return (result);
}

export const CheckBankAccount = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntCheckBankAccount(item)
  return (result);
}

export const LinkBankAccounts = async (item) => {
  const user = await AuthTransactionUser()
  const result = await user.functions.acntLinkBankAccount(item)
  return (result);
}
