// documents
import { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import HeaderSectionLite from "../webb/head-section-lite";

import { toBase64, UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

// import { ftos } from "../../services/srvc-utilities";
import { GetDocumentInfo } from "../../services/srvc-smart-realm";
import { GetDocumentFormats } from "../../services/srvc-smart-realm";


export default function DocumentsNewFormModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const {smid, id } = useParams();
  const history = useHistory();

  const [loading, setLoading]=useState(true);
  const [form, setForm] = useState(true);

  const [info, setInfo] = useState({});
  const [format, setFormats] = useState();
  const [data, setData] = useState({
    form: ''
  });

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        
        // basic info
        setInfo( (await GetDocumentInfo(JSON.stringify({
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }))).data );

        const frmx = await GetDocumentFormats (JSON.stringify({
          data :{user: asset.user},
          user: asset.usid,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }))
        // console.log(frmx)
        setFormats(frmx.data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.usid]);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.form !=='') setForm(true);
  },[data]);


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    history.push(`/${asset.form.substring(0,2)}/documents/new/form/${smid}/${id}/${data.form}`)
  
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall />
    <p className="text-lead text-bold m-0">{info.name}</p>
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />

    {/* action */}
    <p className="">Select Document Template</p>
    <div className="">
      {format && format.map((item,i) => (
        <div className={`d-flex p-2 px-3 back-color-wite cursor hitext ${i<format.length-1 ? 'border-bottom border-light': ''}`} key={i}
          onClick={()=> handleChange("form", item.enid)}
        >
          <div className="me-auto">
            <p className={`m-0 ${data.form === item.enid ? 'text-bold' : 'text-bold'}`}>{item.name}</p>
            <p className="text-small text-color-tone m-0">{item.enid.substring(24)}</p>
          </div>
          <div className="text-end py-2">
            <i className={`bi text-lead m-0 p-0 ${data.form === item.enid ? 'bi-check-circle-fill text-color-success' : 'bi-circle-fill text-color-lite'}`}></i>
          </div>
          
        </div>
      ))}
    </div>



    {/* form */}
    <WebbDividerMedium />
    <form onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Select & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}