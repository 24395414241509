//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import HeaderSectionDark from "../content/webb/head-section-dark";
import HeaderSectionLite from "../content/webb/head-section-lite";
import TextLipsum from "../content/webb/text-lipsum";
import WebbDateTime from "../content/webb/webb-datetime";

import ContactsSearchModule from "../content/contacts/contacts-search";
import ContactsListModule from "../content/contacts/contacts-list";
import ContactsTeamListModule from "../content/contacts/team-list";

import NewContactModule from "../content/contacts/contacts-create";
import NewContactBulkModule from "../content/contacts/contacts-create-bulk";

import NewContactTeamModule from "../content/contacts/team-create";


const listTabs = [
  {name: 'Network', code: 'network'},
  {name: 'Team', code: 'team'}
]


export default function Contacts() {


  const [sort, setSort] = useState('network')

  const [search, setSearch] = useState({})

  const handleSearch = async(filters) => {
    
    setSearch(filters);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Contacts</strong> </>,
      data: 
        <> 
        <HeaderSectionLite data={{name: 'Search Contacts'}}/>
        <ContactsSearchModule search={handleSearch}/>
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: <> All Contacts </>,
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`text-mini text-uppercase p-2 px-3 me-1 rounded-wd text-center cursor
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
            </div>

            <WebbDividerMedium />
            <HeaderSectionDark data={{name: 'Contacts List'}}/> 
            
            <div className={sort === 'network' ? '': 'd-none'}>
              <ContactsListModule data={search || {} } />
            </div>
            
            <div className={sort === 'team' ? '': 'd-none'}>
              <ContactsTeamListModule data={search || {} } />
            </div>

          
          </>,
        foot: <> <WebbDateTime /> </>,
      }}
    actionbar=
      {{      
        head: <> Activity </>,
        data: 
          <>
            <NewContactModule />
            <NewContactBulkModule />
            
            <NewContactTeamModule />
          </>,
        foot: <> </>
      }}    
    
    />
  </>
  )
}