import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

//views - main
import Main from '../viewsxz/main';
// import Land from "../viewsxz/land";

import HomeUs from "../viewsxx/home-indx";
import HomeBz from "../viewsxx/home-bznx";

import Smart from "../viewssc/smart";
import SmartsNewInfo from "../viewssc/smartnewinfo";
import SmartAddNewFile from "../viewssc/smartaddfiles";
import SmartsView from "../viewssc/smartsview";

import SmartsEsinLocation from "../viewssc/smartesinlocation";
import SmartsEditMembers from "../viewssc/smarteditmember";
import SmartsEditFeat from "../viewssc/smartseditfeat";
import SmartEsinSend from "../viewssc/smartsinsend";
import SmartsTracks from "../viewssc/smartstracks";

import DocumentsView from "../viewsvd/documentsview";
import DocumentsTracks from "../viewsvd/documentstracks";

import DocumentsNewXXXX from "../viewsvd/documentsnewxxxx";
import DocumentsNewFile from "../viewsvd/documentsnewfile";
import DocumentsNewForm from "../viewsvd/documentsnewform";
import DocumentsNewFormData from "../viewsvd/documentsnewformdata";

import StampRequest from "../viewsvs/stamprequest";
import StampView from "../viewsvs/stampview";
import SmartAddStamp from "../viewssc/smartaddstamp";


import Invoices from "../viewsvo/invoices";

import InvoicesNew from "../viewsvo/invoicesnew";
import InvoicesView from "../viewsvo/invoicesview";
import InvoicesTracks from "../viewsvo/invoices-tracks";
import InvoicesSubmit from "../viewsvo/invoices-submit";
import InvoiceRulesNew from "../viewsvo/rules-new";

import OrdersNew from "../viewsvo/ordersnew";

import Transfers from "../viewsxt/transfers";
import TransfersView from "../viewsxt/transfersview";
import TransfersNew from "../viewsxt/transfersnew";
import TransfersCreditAccount from "../viewsxt/accountcredit";
import TransfersDebitAccount from "../viewsxt/accountdebit";
import TransfersCredit from "../viewsxt/transfercredit";
import TransfersDebit from "../viewsxt/transferdebit";
import TransfersSubmit from "../viewsxt/transferssubmit";
import TransfersStatement from "../viewsxt/transfersstats";

import Accounts from "../viewsac/accounts";
import AccountsNew from "../viewsac/accountslink";
import AccountsLinkUPI from "../viewsac/accountslinkupi";
import AccountsLinkBank from "../viewsac/accountslinkbank";
import AccountsLinkBase from "../viewsac/accountslinkbase";

import Contacts from "../viewsxc/contacts";
import ContactsView from "../viewsxc/contactsview";
import ContactsEditInfo from "../viewsxc/contactseditinfo";
import ContactsEditAccounts from "../viewsxc/contactseditaccounts";
import ContactsRooms from "../viewsxc/contactsrooms";
import ContactsNew from "../viewsxc/contactsnew";
import ContactsNewIndividual from "../viewsxc/contactsnewindx";
import ContactsNewBusiness from "../viewsxc/contactsnewbsnx";
import ContactsNewTeam from "../viewsxc/contactsnewtmbx";
import ContactsNewBulk from "../viewsxc/contactsnewbulk";

import ContactsTeam from "../viewsxc/contacts-team";

import Settings from "../viewsxs/settings";


// views - auth - onbd
import AuthMobileFirebase from "../viewsxa/auth-mobile-firebase";
import ANewMobileFirebase from "../viewsxa/anew-mobile-firebase";
import AuthMailFirebase from "../viewsxa/auth-mail-firebase";
import AuthMailCheckFirebase from "../viewsxa/auth-mail-check";
import AuthNext from "../viewsxa/auth-next";
import AuthSessionX from "../viewsxa/auth-session-x";

import OnboardName from "../viewsxb/onboardname";
import OnboardAvtr from "../viewsxb/onboardavtr";
import OnboardDomn from "../viewsxb/onboarddomn";
import OnboardDocs from "../viewsxb/onboarddocs";
import OnboardComs from "../viewsxb/onboardcoms";
import OnboardLocs from "../viewsxb/onboardlocs";
import OnboardTerm from "../viewsxb/onboardterm";
import OnboardDone from "../viewsxb/onboardx";
import OnboardBusinessNew from "../viewsxb/businessnew";
import OnboardBusinessName from "../viewsxb/businessname";
import OnboardBusinessDocs from "../viewsxb/businessdocs";
import OnboardBusinessComs from "../viewsxb/businesscoms";
import OnboardBusinessLocs from "../viewsxb/businesslocs";
import OnboardBusinessTerms from "../viewsxb/businessterm";
import DocumentsNewDataRecord from "../viewsvd/documentnewdatarecord";
import BusinessAccountCreateView from "../viewsac/businessaccountcreate";
import SubscriptionCheckView from "../viewsub/subscriptioncheck";
import TeamsView from "../viewsxc/teamsview";
import TeamsEditInfo from "../viewsxc/teamseditinfo";
import AuthMailOTP from '../viewsxa/auth-mail-otp'

const routes = [

  { path:'/', component: Main, auth:false },

  { path:'/in/home', component: HomeUs, auth:true },
  { path:'/bz/home', component: HomeBz, auth:true },

  { path:'/in/stamp/request', component: StampRequest, auth:true },
  { path:'/in/stamp/view/:id', component: StampView, auth:true },

  { path:'/bz/stamp/request', component: StampRequest, auth:true },
  { path:'/bz/stamp/view/:id', component: StampView, auth:true },


  //----------------------------------------------------------------------------Indx smart routes started

  { path:'/in/smart', component: Smart, auth:true },
  { path:'/in/smart/new/info', component: SmartsNewInfo, auth:true },
  { path:'/in/smart/add/file/:id', component: SmartAddNewFile, auth:true },
  { path:'/in/smart/view/:id', component: SmartsView, auth:true },
  { path:'/in/smart/esin/location/:id', component: SmartsEsinLocation, auth:true },
  { path:'/in/smart/edit/mmbr/:id', component: SmartsEditMembers, auth:true },
  { path:'/in/smart/edit/feat/:id', component: SmartsEditFeat, auth:true },
  { path:'/in/smart/esin/send/:id', component: SmartEsinSend, auth:true },
  { path:'/in/smart/v/track/:id', component: SmartsTracks, auth:true },
  { path:'/in/smart/add/stamp/:id', component: SmartAddStamp, auth:true },



  { path:'/in/documents/new/xxxx/:smid/:id', component: DocumentsNewXXXX, auth:true },
  { path:'/in/documents/new/file/:smid/:id', component: DocumentsNewFile, auth:true },
  { path:'/in/documents/new/form/:smid/:id', component: DocumentsNewForm, auth:true },
  { path:'/in/documents/new/form/:smid/:id/:frmx', component: DocumentsNewFormData, auth:true },
  { path:'/in/documents/new/data/:smid/:id', component: DocumentsNewDataRecord, auth:true },
  

  
//--------------------------------------------------------------------------------Indx smart route ended
  
  
  { path:'/in/documents/view/:smid/:id', component: DocumentsView, auth:true },
  { path:'/in/documents/v/track/:id', component: DocumentsTracks, auth:true },

  


  //-----------------------------------------------------------------------------------------Bznx smart route started


  { path:'/bz/smart', component: Smart, auth:true },
  { path:'/bz/smart/new/info', component: SmartsNewInfo, auth:true },
  { path:'/bz/smart/add/file/:id', component: SmartAddNewFile, auth:true },
  { path:'/bz/smart/view/:id', component: SmartsView, auth:true },
  { path:'/bz/smart/esin/location/:id', component: SmartsEsinLocation, auth:true },
  { path:'/bz/smart/edit/mmbr/:id', component: SmartsEditMembers, auth:true },
  { path:'/bz/smart/edit/feat/:id', component: SmartsEditFeat, auth:true },
  { path:'/bz/smart/esin/send/:id', component: SmartEsinSend, auth:true },
  { path:'/bz/smart/v/track/:id', component: SmartsTracks, auth:true },
  { path:'/bz/smart/add/stamp/:id', component: SmartAddStamp, auth:true },

  { path:'/bz/documents/new/xxxx/:smid/:id', component: DocumentsNewXXXX, auth:true },
  { path:'/bz/documents/new/file/:smid/:id', component: DocumentsNewFile, auth:true },
  { path:'/bz/documents/new/form/:smid/:id', component: DocumentsNewForm, auth:true },
  { path:'/bz/documents/new/form/:smid/:id/:frmx', component: DocumentsNewFormData, auth:true },
  { path:'/bz/documents/new/data/:smid/:id', component: DocumentsNewDataRecord, auth:true },

  //----------------------------------------------------------------------------------------- Bznx smart route ended
  
  { path:'/bz/documents/view/:smid/:id', component: DocumentsView, auth:true },
  { path:'/bz/documents/v/track/:id', component: DocumentsTracks, auth:true },

  


  { path:'/in/invoices', component: Invoices, auth:true },
  { path:'/in/invoices/new', component: InvoicesNew, auth:true },
  { path:'/in/invoices/submit', component: InvoicesSubmit, auth:true },
  { path:'/in/invoices/view/:id', component: InvoicesView, auth:true },
  { path:'/in/invoices/v/track/:id', component: InvoicesTracks, auth:true },
  { path:'/in/invoices/v/track/:id', component: InvoicesTracks, auth:true },

  { path:'/in/orders/new', component: OrdersNew, auth:true },

  { path:'/in/invoices/rules', component: InvoiceRulesNew, auth:true },

  { path:'/bz/invoices', component: Invoices, auth:true },
  { path:'/bz/invoices/new', component: InvoicesNew, auth:true },
  { path:'/bz/invoices/submit', component: InvoicesSubmit, auth:true },
  { path:'/bz/invoices/view/:id', component: InvoicesView, auth:true },
  { path:'/bz/invoices/v/track/:id', component: InvoicesTracks, auth:true },
  { path:'/bz/invoices/v/track/:id', component: InvoicesTracks, auth:true },

  { path:'/bz/orders/new', component: OrdersNew, auth:true },
  
  { path:'/bz/invoices/rules', component: InvoiceRulesNew, auth:true },

  { path:'/in/transfers', component: Transfers, auth:true },
  { path:'/in/transfers/view/:id', component: TransfersView, auth:true },
  { path:'/in/transfers/new', component: TransfersNew, auth:true },
  { path:'/in/transfers/account/credit', component: TransfersCreditAccount, auth:true },
  { path:'/in/transfers/account/debit', component: TransfersDebitAccount, auth:true },
  { path:'/in/transfers/credit', component: TransfersCredit, auth:true },
  { path:'/in/transfers/debit', component: TransfersDebit, auth:true },
  { path:'/in/transfers/submit', component: TransfersSubmit, auth:true },
  { path:'/in/transfers/statement', component: TransfersStatement, auth:true },

  { path:'/bz/transfers', component: Transfers, auth:true },
  { path:'/bz/transfers/view/:id', component: TransfersView, auth:true },
  { path:'/bz/transfers/new', component: TransfersNew, auth:true },
  { path:'/bz/transfers/account/credit', component: TransfersCreditAccount, auth:true },
  { path:'/bz/transfers/account/debit', component: TransfersDebitAccount, auth:true },
  { path:'/bz/transfers/credit', component: TransfersCredit, auth:true },
  { path:'/bz/transfers/debit', component: TransfersDebit, auth:true },
  { path:'/bz/transfers/submit', component: TransfersSubmit, auth:true },
  { path:'/bz/transfers/statement', component: TransfersStatement, auth:true },

  { path:'/in/accounts', component: Accounts, auth:true },
  { path:'/in/accounts/link', component: AccountsNew, auth:true },
  { path:'/in/accounts/link/upi', component: AccountsLinkUPI, auth:true },
  { path:'/in/accounts/link/bank', component: AccountsLinkBank, auth:true },
  { path:'/in/accounts/link/base', component: AccountsLinkBase, auth:true },
  { path:'/in/accounts/create/business', component: BusinessAccountCreateView, auth:true},
  { path:'/in/accounts/subscription', component: SubscriptionCheckView, auth:true },





  { path:'/bz/accounts', component: Accounts, auth:true },
  { path:'/bz/accounts/link', component: AccountsNew, auth:true },
  { path:'/bz/accounts/link/upi', component: AccountsLinkUPI, auth:true },
  { path:'/bz/accounts/link/bank', component: AccountsLinkBank, auth:true },
  { path:'/bz/accounts/link/base', component: AccountsLinkBase, auth:true },
  { path:'/bz/accounts/create/business', component: BusinessAccountCreateView, auth:true},
  { path:'/bz/accounts/subscription', component: SubscriptionCheckView, auth:true },




  { path:'/in/contacts', component: Contacts, auth:true },
  { path:'/in/contacts/view/:id', component: ContactsView, auth:true },
  { path:'/in/contacts/edit/:id', component: ContactsEditInfo, auth:true },
  { path:'/in/contacts/accounts/:id', component: ContactsEditAccounts, auth:true },
  { path:'/in/contacts/room/:id', component: ContactsRooms, auth:true },
  { path:'/in/contacts/new', component: ContactsNew, auth:true },
  { path:'/in/contacts/new/individual', component: ContactsNewIndividual, auth:true },
  { path:'/in/contacts/new/business', component: ContactsNewBusiness, auth:true },
  { path:'/in/contacts/new/team', component: ContactsNewTeam, auth:true },
  { path:'/in/contacts/new/bulk', component: ContactsNewBulk, auth:true },

  { path:'/bz/contacts', component: Contacts, auth:true },
  { path:'/bz/contacts/view/:id', component: ContactsView, auth:true },
  { path:'/bz/contacts/edit/:id', component: ContactsEditInfo, auth:true },
  { path:'/bz/contacts/accounts/:id', component: ContactsEditAccounts, auth:true },
  { path:'/bz/contacts/room/:id', component: ContactsRooms, auth:true },
  { path:'/bz/contacts/new', component: ContactsNew, auth:true },
  { path:'/bz/contacts/new/individual', component: ContactsNewIndividual, auth:true },
  { path:'/bz/contacts/new/business', component: ContactsNewBusiness, auth:true },
  { path:'/bz/contacts/new/team', component: ContactsNewTeam, auth:true },
  { path:'/bz/contacts/new/bulk', component: ContactsNewBulk, auth:false },

  { path:'/bz/team', component: ContactsTeam, auth:true },
  { path:'/bz/team/view/:id', component: TeamsView, auth:true },
  { path:'/bz/team/edit/:id', component: TeamsEditInfo, auth:true },

  { path:'/in/settings', component: Settings, auth:true },
  { path:'/bz/settings', component: Settings, auth:true },

  { path:'/auth/mail', component: AuthMailOTP, auth:false },
  { path:'/auth/mail/check', component: AuthMailCheckFirebase, auth:false },
  { path:'/auth/next', component: AuthNext, auth:true },
  { path:'/auth/x', component: AuthSessionX, auth:true },

  { path:'/account/access', component: AuthMobileFirebase, auth:true },
  { path:'/account/create', component: ANewMobileFirebase, auth:true },

  { path:'/onboard/user', component: OnboardName, auth:true },
  { path:'/onboard/avtr', component: OnboardAvtr, auth:true },
  { path:'/onboard/domain', component: OnboardDomn, auth:true },
  { path:'/onboard/docs', component: OnboardDocs, auth:true },
  { path:'/onboard/contact', component: OnboardComs, auth:true },
  { path:'/onboard/location', component: OnboardLocs, auth:true },
  { path:'/onboard/terms', component: OnboardTerm, auth:true },
  { path:'/onboard/success', component: OnboardDone, auth:true },

  { path:'/business/new', component: OnboardBusinessNew, auth:true },
  { path:'/business/name', component: OnboardBusinessName, auth:true },
  { path:'/business/docs', component: OnboardBusinessDocs, auth:true },
  { path:'/business/contact', component: OnboardBusinessComs, auth:true },
  { path:'/business/location', component: OnboardBusinessLocs, auth:true },
  { path:'/business/terms', component: OnboardBusinessTerms, auth:true },
]

const PrivateRoute = (props) => {
  const location = useLocation();
  //console.log (location.pathname)
  const { user } = useAuth();
  return user 
  ? ( <Route { ...props } /> ) 
  : ( <Redirect to={{ pathname: "/", state: { from: location } }} /> )
};

export default function Routes() {

  return (
    <Switch>
      {routes.map ((item,i)=>(item.auth
      ? <PrivateRoute
          key={i}
          path={item.path}
          component={item.component}
          exact
        />
      : <Route
          key={i}
          path={item.path}
          component={item.component}
          exact
        />
      ))}
    </Switch>
  );
}