// init
import axios from "axios";
const base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-user-jsdxf/endpoint"


// Function Calls

export const GetUserAccounts = async (item) => {

  const basx = base + '/users/accounts';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const NewUserAccount = async (item) => {

  const basx = base + '/users/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const NewBusinessAccount = async (item) => {

  const basx = base + '/business/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}


export const SetUserName = async (item) => {

}


export const AccountDetails = async (item) => {

  const basx = base + '/users/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const AccountSubscriptionDetails = async (item) => {

  const basx = base + '/users/subscription';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}


export const GetUserKYCDocuments = async (item) => {

  const basx = base + '/users/kyc/documents';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const UserUploadKYCDocuments = async (item) => {

  const basx = base + '/users/upload/kyc';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const BusinessTeamMembers = async (item) => {

  const basx = base + '/business/team/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}
