//account
import { useEffect, useState } from "react";
import { useHistory  } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { CreateBaseAccount } from "../../services/srvc-accounts-realm";

export default function AccountsLinkBaseModule() {

  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  const [loading,setLoading]=useState(false);
  const [form, setForm] = useState(false);

  const [data, setData] = useState({
    name: asset.name,
    usid: asset.usid
  });
  
  
  const [txtf, setTextForm] = useState('');
  const [chck, setCheck] = useState(false);
  const [errr, setError] = useState(true);

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.name !=='' && data.usid !== '') 
      setForm(true);
  },[data]);


  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (form) {
      var datx ={
        data: data,
        user: asset.usid,
        webb: process.env.REACT_APP_WEBB_SITE_NMBR
      }
      console.log (JSON.stringify(datx));
      // api call
      const res = CreateBaseAccount (JSON.stringify(datx));
      console.log (res);
      
      if (res.data) {
        //history.push(`/${asset.form}/accounts`);
      }
      setLoading (false);
    } 
    else { }

  }

  if (loading){
    return ( <> <WebbLoader/> </> )
  }


  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Create and Link Online Account'}}/>
    <WebbDividerSmall />


    <form onSubmit={handleSubmit}>
      {/* content */}
      <div className="">

        <div className="form-group mb-3">  
          <label className="form-label small">Account Owner Name <FormNeeded /></label>
          <p className="m-0">{data.name}</p>
        </div>

      </div>

      {/* text */}
      <div className="">
        <div className="mb-3">
          <small className={errr? 'text-danger' : 'text-success'}>{txtf}</small>
        </div>

      </div>
      
      {/* action */}
      <WebbDividerMedium/>
      <div className="d-grid">
            
        <button 
          className={`btn btn-primary back-color-main height-md`}
          type="submit"
        ><small>Create & Link</small></button>

      </div>
    
    </form>

  </>
  )
}


