// init transfers
import axios from "axios";
const base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-trxn-ingio/endpoint"
// const base = "https://ap-south-1.aws.data.mongodb-api.com/app/decentro-main-app-qylmm/endpoint"


// Function Calls

export const GetTransfersList = async (item) => {

  const basx = base + '/transfers/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const TransfersCreate = async (item) => {

  const basx = base + '/transfers/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}


export const WithdrawlCreate = async (item) => {

  const basx = base + '/account/withdrawl';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}
  // console.log(datx)

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    //console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    var {data} = error.response.data
    // console.log (error);
    return {data: false, memo:data.message}
  }

}



//------------------------------------------------------------------------------------------------

export const GetServiceChargesTransaction = async (item) => {

  const basx = base + '/transfers/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}


export const GetUserTransactionsListWait = async (item) => {
  
}

export const GetUserTransactionsListSchedule = async (item) => {

}

export const GetUserTransactionsCredit = async (item) => {

}

export const GetUserTransactionsDebit = async (item) => {

}

export const GetTransactionDetail = async (item) => {

}

// contact transfers
export const GetContactTransactions = async (item) => {

}

// transactions 

export const TransfersAccountCredit = async (item) => {

}

export const TransfersAccountDebit = async (item) => {

}

export const TransfersCredit = async (item) => {

}

export const TransfersDebit = async (item) => {

}

export const TransfersCancel = async (item) => {

}

export const TransfersDecline = async (item) => {

}

export const TransfersConfirm = async (item) => {

}