import { useEffect, useState } from "react";

import WebbDividerMedium from "../webb/webb-divider-md";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbSpinText from "../webb/webb-spintext";
import ListNoData from "../webb/list-nodata";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { docxGetPendingEsin, GetUserDocumentsListWait } from "../../services/srvc-smart-realm";
import { GetUserKycLink } from "../../services/srvc-iamx-realm";

export default function DocumentsListWaitModule() {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();

  const [loading,setLoading]=useState(true);
  
  const [data, setData] = useState([]);
  const [esinlist, setEsinlist] = useState([])

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        setData ((await GetUserDocumentsListWait(JSON.stringify({
          data: { user: asset.user },
          user: asset.user,
        }))).data)

        var res = (await docxGetPendingEsin(JSON.stringify({
          data: { user: asset.user },
          user: asset.user,
        }))).data

        // console.log(res)
        setEsinlist (res)
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.user]);
  
  if (loading){ return ( <> </> ) }

  if (data && data.user.length == 0 && data.business.length==0 && esinlist && esinlist.length == 0 ) {
    return (<> <p className="text-secondary">No Pending Actions</p> </>)
  }

  return (
  <>

    {/*  */}
    <div className={data.user && data.user.length === 0 ? 'd-none' : ''}>
    </div>
    <div className={data.user && data.user.length === 0 ? 'd-none' : "rounded-wd border"}>
    {data && data.user.length > 0 && data.user.map((item, i) => ( 
      <div className={i < data.user.length-1 ? 'border-bottom' : ''} key={i}>
      <div className="p-3 text-color-next hilite cursor" onClick={() => window.open(item.link)}>
        <div className="d-flex cursor">

          <div className="">
            <div className="back-color-warning rounded-md py-1 text-center" 
              style={{'width':'2.4rem', 'height':'2.4rem'}}>
              <i className="bi bi-bell text-color-wite text-icon-sm"></i>
            </div>            
          </div>

          <div className="ms-2">
            <p className="text-normal text-bold m-0" style={{lineHeight:'1.25rem'}}>Complete User KYC</p>
            <p className="text-dark m-0">{`Document: ${item.docn}`}</p>
            <p className="m-0 text-small">{'Status: Pending'}</p>
          </div>

          <div className="ms-auto py-2">
            <i className="bi bi-chevron-right text-lead text-color-tone"></i>            
          </div>

        </div>
       
      </div>
    </div>
    ))}
    </div>

    {/* -------------------------------------------- */}
    
    <div className={data && data.business.length === 0 ? 'd-none' : ''}>
      <WebbDividerSmall />
    </div>
    
    <div className={data && data.business.length === 0 ? 'd-none' : "rounded-wd border"}>
    {data && data.business.length > 0 && data.business.map((item, i) => (
  
      <div className={i < data.business.length-1 ? 'border-bottom' : ''} key={i}>
        <div className="p-3 text-color-next hilite cursor" onClick={() => window.open(item.link)}>
          <div className="d-flex">

            <div className="">
              <div className="back-color-warning rounded-md py-1 text-center" 
                style={{'width':'2.4rem', 'height':'2.4rem'}}>
                <i className="bi bi-bell text-color-wite text-icon-sm"></i>
              </div>            
            </div>

            <div className="ms-2">
              <p className="text-normal text-bold m-0" style={{lineHeight:'1.25rem'}}>Complete Business KYC</p>
              <p className="text-dark m-0">{`Document: ${item.docn}`}</p>
              <p className="m-0 text-small">{'Status: Pending'}</p>
            </div>

            <div className="ms-auto py-2">
              <i className="bi bi-chevron-right text-lead text-color-tone"></i>            
            </div>

          </div>
        </div>
      
      </div>

    ))}
    </div>

    {/* --------------------------------------------------- */}
    <div className={esinlist && esinlist.length === 0 ? 'd-none' : ''}>
      <WebbDividerSmall />
    </div>

    <div className={esinlist && esinlist.length === 0 ? 'd-none' : "rounded-wd border shadow-sm"}>
    {esinlist && esinlist.length > 0 && esinlist.map((item, i) => (
      <div className={i < esinlist.length-1 ? 'border-bottom' : ''} key={i}>
        <div className="p-3 text-color-next hilite cursor" onClick={() => window.open(item.link)}>
          <div className="d-flex">

            <div className="">
              <div className="back-color-warning rounded-md py-1 text-center" 
                style={{'width':'2.4rem', 'height':'2.4rem'}}>
                <i className="bi bi-bell text-color-wite text-icon-sm"></i>
              </div>            
            </div>

            <div className="ms-2">
              <p className="text-normal text-bold m-0" style={{lineHeight:'1.25rem'}}>Complete E-Signature</p>
              <p className="text-dark m-0">{`Document: ${item.docn}`}</p>
              <p className="m-0 text-small">{'Status: Pending'}</p>
            </div>

            <div className="ms-auto py-2">
              <i className="bi bi-chevron-right text-lead text-color-tone"></i>            
            </div>

          </div>
         
        </div>
      </div>
    ))}
    </div>

  </>
  )
}