// dashboard shortcuts
import ActionCardMedium from "../webb/ctas-actioncard-md";

import { UserForm } from "../../services/srvc-utilities";
import { GetAuthUser } from "../../services/srvc-auth-user";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";
import { AccountBalance } from "../../services/srvc-accounts-realm";
import WebbSpinText from "../webb/webb-spintext";
import { useEffect, useState } from "react";

const list = (require("../../data/data-estamp-actions.json")).data.filter(item => item.actv);

export default function StampsActionsModule() {
  
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const data = (list.filter(item => item.user.includes(asset.form)));

  const [loading,setLoading] = useState(true)

  
  // if (loading){ return ( <> <WebbSpinText /> </> ) }
 
  return (
  <>
    {data && data.map((item, i) => (
    
      <ActionCardMedium data={item} key={i} />    
    
    ))}
  </>
  )
}