// documents
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webb/webb-loader";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { SetInvoicesCancel } from "../../services/srvc-invoices-realm";
import { SetInvoicesDecline , SetInvoicesConfirm } from "../../services/srvc-invoices-realm";

const list = require('../../data/list-invoices-actions.json').data.filter(item=>item.actv)

export default function DocumentActions(props) {
  
  const asset = UserForm() === 'in' ? GetLocalUser() : GetLocalBusiness();
  //console.log(asset)
  const {id} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  
  const data = props.data;
  const lstx = (list.filter(item => item.user.includes(asset.form))).filter(x => x.crdb.includes(data.mode))
  
  const handleConfirm = async() => {
    
    if (data.chck) {
      setLoading(true);
      data.cred={user:data.cred.item,mail:data.cred.mail,name:data.cred.name}
      data.debt={user:data.debt.item,mail:data.debt.mail,name:data.debt.name}
      const response = await SetInvoicesConfirm ({
        data: { enid: id, invoice:data },
        user: asset.user
      });
      
      setLoading(false);
      if (response) {
        history.push(`/${asset.form.substring(0,2)}/invoices/submit`);
      }
    }
    else {alert ('Low Funds! Please Add Funds via Dashboard')}


  }

  const handleDecline = async() => {
    setLoading(true);
    const response = await SetInvoicesDecline ({
      data: { enid: id },
      user: asset.user,
    });
    console.log (response)
    setLoading(false);
    if (response) {
      history.push(`/${asset.form.substring(0,2)}/invoices/submit`);
    }
  }

  const handleCancel = async() => {
    setLoading(true);
    const response = await SetInvoicesCancel ({
      data: { enid: id },
      user: asset.user,
    });
    setLoading(false);
    console.log (response)
    if (response) {
      history.push(`/${asset.form.substring(0,2)}/invoices/submit`);
    }
  }

  
  const handleMembers = async() => {
    setLoading(true);
    history.push(`/${asset.form.substring(0,2)}/documents/edit/mmbr/${id}`)
  }

  const handleFiles = async() => {
    setLoading(true);
    history.push(`/${asset.form.substring(0,2)}/documents/new/xxxx/${id}`)
  }

  const handleTime = async() => {
    setLoading(true);
    history.push(`/${asset.form.substring(0,2)}/invoices/v/track/${id}`)
  }

  
  const handleButton = async(code) => {

    switch(code) {
      case 'confirm':
        handleConfirm();
        break;
      case 'decline':
        handleDecline();
        break;
      case 'cancel':
        handleCancel();
        break;              
      case 'members':
        handleMembers();
        break;
      case 'file':
        handleFiles();
        break;
      case 'time':
        handleTime();
        break;
      default:
        // code block
    }

  }

  if (loading) return <WebbLoader />

  return (
    <>
      <div className="rounded-wd back-color-wite">

        <div className={`row row-cols-${lstx.length} g-0`} >
        {lstx && lstx.length>0 && lstx.map ((item, i) => 
          <div className="col d-grid" key={i}  
            style={{pointerEvents: item.stat.includes(data.status) ? "all" : "none"}} 
            >
            <div
              className={`text-center m-2 py-2 hilite text-color-${item.stat.includes(data.status)?"main":"lite"}`}
              onClick={()=> handleButton(item.code)} 
              style={{cursor:item.stat.includes(data.status) ? 'pointer': ''}}
              >
                <i className={item.icon}
                  style={{fontSize:'2rem'}}></i>
                <p className="text-dark m-0 p-0 text-sm"><small>{item.name}</small></p>
            </div>
          </div>

        )}
        </div>
  
      </div>
    </>
    )
  }