//main
import ContentFormatXX from "../content/webc/content-format-xx";

import { UserForm } from "../services/srvc-utilities";
import OrdersNewModule from "../content/orders/orders-new";

export default function OrdersNew() {

  return (
  <>
    <ContentFormatXX 
      head='New PO'
      link={`/${UserForm()}/invoices`}
      form= 'small'
      data= {
        <>
          <OrdersNewModule />
        </>
      } 
    />
  </>
  )
}