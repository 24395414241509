// init documents
// estamp create, list of estamp, status, merge 

import axios from 'axios'
const base="https://ap-south-1.aws.data.mongodb-api.com/app/contrato-estm-haves/endpoint"

// Function Calls

export const RequestStampPaper = async (item)=>{

  const basx = base + '/estamp/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const StampList = async (item)=>{

  const basx = base + '/stamp/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const SmartStampDetails = async (item)=>{

  const basx = base + '/estamp/view';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = item

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    //console.log (error);
    return {data: false}
  }

}
