// init transfers
import axios from "axios";
const base = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-invc-rcxxc/endpoint"


// Function Calls

// read functions
export const InvoicesStatus = async (item) => {


  const basx = base + '/invoices/status';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

// create new invoice
export const InvoicesCreate = async (item) => {

  const basx = base + '/invoices/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const CreateNewOrders = async (item) => {

}


export const GetInvoicesList = async (item) => {

  const basx = base + '/invoices/list';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}
export const InvoicesListWait = async (item) => {

}

export const InvoicesDetail = async (item) => {

  const basx = base + '/invoices/details';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }


}

export const GetInvoiceFiles = async (item) => {

}

// actions
export const SetInvoicesCancel = async (item) => {

  // console.log(item)
  const basx = base + '/invoices/cancel';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}
  

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const SetInvoicesDecline = async (item) => {

  const basx = base + '/invoices/reject';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}

export const SetInvoicesConfirm = async (item) => {

  const basx = base + '/invoices/approve';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
  }
  const datx = {data: item.data, user: item.user}

  var result;
  try {
    result = await axios.post(basx, datx, {headers: head});
    // console.log (result)
    return ([200, 201].includes(result.status)) ? result.data : {data: false}
  } catch (error) {
    // console.log (error);
    return {data: false}
  }

}


