// transfers list
import { numc } from '../../services/srvc-utilities';
import WebbDividerMedium from './webb-divider-md';

const code = require('../../data/code-invoices.json').data

export default function InvoiceListCard(props) {

  const data = props.data;
  const meta = props.data.meta;

  return (
  <>
    <div className="d-flex p-2 px-3 border hilite mb-3">

      <div className="">
        {/* <p className="fw-bold m-0 p-0 mb-1">
          {data.name}
          <span className="ps-2"></span>
          <span className={`badge small bg-tone ${''}`}>
            {data.crdb === "S" ? "Customer" : "Seller"}
          </span>
        </p> */}
        <p className="fw-bold m-0 p-0 text-primary">
          {'Inv: '}{data.meta.nmbr || '***'}
        </p>
        <p className="m-0 p-0 text-dark">
          {'Memo: '}{data.meta.name}
        </p>
        <p className="small m-0 p-0 text-dark">
          {'Invoiced: '}{(new Date(data.date.stts)).toLocaleString()}
        </p>
        <p className="small m-0 p-0 text-dark">
          {'Due: '}{(new Date(data.date.ents)).toLocaleString()}
        </p>

      </div>

      <div className="ms-auto text-end">
        <p className="m-0 p-0">
          <span  className="text-muted">{data.crdb==="S" ? '-' : '+'}</span>
          <span>{' '}</span>
          <span  className="fw-bold ">{numc(data.amnt)}</span>
        </p>
        <div className=''></div>

        <p className={`m-0 text-dark`}>
          {code.find(item => item.code == data.stat).text}
          <i className={`ms-1 bx bxs-circle small text-${code.find(item => item.code == data.stat).colr}`}></i>  
        </p>

      </div>

    </div>

    <div className="border-bottom my-3 d-none"></div>
  </>
  )
}

//${code.find(x=> x.code == meta.stat).colr}`}>
// {code.find(x=>parseInt(x.code) == meta.stat).text.toUpperCase()}