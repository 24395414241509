// documents
import { Link, useParams } from "react-router-dom";

import WebbDividerMedium from "./webb-divider-md";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

export default function InvoiceFiles(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const { smid,id } = useParams();
  
  const data = props.data
  //console.log(data)
  // console.log (props.status)

  

  if (data.length === 0) 
  return (
  <> 
    <span> No Files Attached. Please  </span>
    <Link to={`/${asset.form.substring(0,2)}/documents/new/xxxx/${smid}/${id}`}>add / upload a file</Link>
    <WebbDividerMedium/>
  </>
  )

  return (
  <>
    <div className={`rounded-wd back-color-wite mb-3`}>
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="text-bold m-0 p-0 text-sm">{item.name}</p>
          <p className="m-0 p-0 text-color-tone text-sm">ID: {item.number}</p>
          <p className="m-0 p-0 text-color-tone text-sm">{'Created: '}{(new Date(item.created)).toLocaleString()}</p>
        </div>

        <div className={`text-end py-2`}>
          <a href={item.link} target="_blank">
          <div 
            className="btn btn-sm btn-light border hidark border-none rounded-wd p-1 px-3"
            ><small>Download</small>
          </div>
          </a>
        </div>

      </div>
      
      <div className={`border-bottom ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
    ))}
    </div>

    {/* replace option */}
 
  </>
  )
}