// cancel button
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AccountBalance } from '../../services/srvc-accounts-realm';
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-user';
import { UserForm } from '../../services/srvc-utilities';

export default function ActionCardSmall(props) {

  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const data = props.data;

  //console.log(data)


  return (
  <>
    <Link 
      disabled={!data.actv}
      className="m-0 p-0"
      style={{ pointerEvents: data.actv ? '' : 'none' }}
      to={data.link}>
      <div className={`d-flex border rounded-wd back-color-wite p-2 px-3 mb-2 hitone`}>

          <div className="me-auto">
            <p className={ `text-bold m-0 p-0 ${!data.actv ? 'text-color-tone' : ''}`}>{data.name}</p>
            <p className="text-color-tone m-0">{data.text}</p>
          </div>

          <div className="">
            <i className={`m-0 p-0 text-color-tone bi-chevron-right`} >
            </i>
          </div>
        </div>

        
    </Link>

  </>
  )
}