//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import HeaderSectionDark from "../content/webb/head-section-dark";
import WebbDateTime from "../content/webb/webb-datetime";

import SmartSearchModule from "../content/smart/smart-search";

import ContractListModule from "../content/smart/smarts-list";
import StampListModule from "../content/stamps/stamp-list";

import SmartActionsModule from "../content/smart/smart-actions";
import StampsActionsModule from "../content/stamps/stamp-actions";

const listTabs = [
  {name: 'Contracts', code: 'contracts'},
  // {name: 'E-Stamp', code: 'estm'}
]

export default function Smart() {

  const [sort, setSort] = useState('contracts');
  const [search, setSearch] = useState();

  const handleSearch = async(item) => {
    // console.log('srch: ', sort)
    setSearch(item);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-tone">Vault</strong> </>,
      data: 
        <> 

          <HeaderSectionLite data={{name: 'Search Documents'}}/>
          <SmartSearchModule search={handleSearch}/>
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: 'Contracts & Documents',
        data: 
          <> 
            {/* navs */}
            <div className="d-flex justify-content-start">
              {listTabs && listTabs.length > 0 && listTabs.map((item, i) => ( 
                
                <span className={`p-2 px-3 me-1 rounded-wd text-mini text-center cursor text-uppercase
                  ${sort === item.code ? 'back-color-next text-color-wite': 'back-color-lite text-color-tone'}`}
                  key={i} style={{fontSize:'0.7rem'}} 
                  onClick={() => setSort(item.code)}
                  >{item.name}</span>

              ))}
            </div>
            
            {/* content */}
            <WebbDividerMedium />
            <HeaderSectionDark data={{name: 'Documents List'}}/> 
            
            <div className={sort === 'contracts' ? '': 'd-none'}>
              <ContractListModule data={search || {} } />
            </div>

            <div className={sort === 'estm' ? '': 'd-none'}>
              <StampListModule data={search || {} } />
            </div>

            <WebbDividerMedium />
            <WebbDividerMedium />
          </>,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: 'Activity',
        data: 
          <>
            <SmartActionsModule />
            
            <StampsActionsModule />
            <WebbDividerMedium/>
            
          </>,
        foot: <> </>,
      }}    
    
    />
  </>
  )
}