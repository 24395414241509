// action
import ActionCardSmall from "../webb/ctas-actioncard-sm";

import { UserForm } from "../../services/srvc-utilities";

export default function NewAccountsModule() {
 
  const data = {
    name: 'Link Account',
    link: `/${UserForm()}/accounts/link`
  }

  return (
  <>
    <ActionCardSmall data={data}/>
  </>
  )
}