// init documents
// to check for today... 

import axios from "axios";

var smart ="https://ap-south-1.aws.data.mongodb-api.com/app/contrato-docx-uqaiv/endpoint"

var file = "https://ap-south-1.aws.data.mongodb-api.com/app/contrato-files-dyohy/endpoint"


export const SmartContractCreate = async (item) => {

    const basx = smart + '/contract/create';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }


  export const SmartGetInfo= async (item) => {
    const basx = smart + '/contract/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }


  export const SmartGetDocumentList= async (item) => {
    const basx = smart + '/contract/read/documents';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const SmartGetContractList= async (item) => {
    const basx = smart + '/contract/get/user/contracts';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }

  export const SmartGetContractCount= async (item) => {
    const basx = smart + '/contract/get/count';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      console.log (error);
      return {data: false}
    }
  
  }


  export const SmartAddFiles= async (item) => {
    const basx = smart + '/contract/set/files';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }
  
  }


  export const GetDocumentInfo=async(item)=>{

    const basx = smart + '/document/read';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const GetDocumentFiles = async(item)=>{

    const basx = file + '/files/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const SetDocumentEsinLocation = async(item)=>{

    const basx = smart + '/document/set/location';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const GetDocumentFeat = async(item)=>{

    const basx = smart + '/document/get/feat';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      //console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const SetDocumentFeat = async(item)=>{

    const basx = smart + '/document/set/feature';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const SendDocumentEsin = async(item)=>{

    const basx = smart + '/document/send';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }



  export const GetDocumentMembers = async(item)=>{

    const basx = smart + '/document/read/member';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const SetMembersList = async(item)=>{

    const basx = smart + '/document/set/member';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }



  }

  export const SetDocumentFiles = async(item)=>{

    const basx = smart + '/document/set/file';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const SetDocumentDataRecord = async(item)=>{

    const basx = smart + '/document/set/data/record';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const GetDocumentFormats = async(item)=>{
    
    const basx = smart + '/user/template/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const MergeDocumentStamp=async(item)=>{
    
    const basx = smart + '/document/merge/stamp';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }
  
  
  
  
  export const GetSmartMembers=async(item)=>{
    
    const basx = smart + '/contract/smart/members';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const SmartSetMembers=async(item)=>{
    
    const basx = smart + '/contract/set/members';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const SmartSetEsinLocation=async(item)=>{
    
    const basx = smart + '/contract/esin/location';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const GetSmartFeat=async(item)=>{
    
    const basx = smart + '/contract/get/feature';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const SetSmartFeat=async(item)=>{
    
    const basx = smart + '/contract/set/feature';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const SmartSendEsin=async(item)=>{
    
    const basx = smart + '/contract/send/esin';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const DocumentCancel=async(item)=>{
    
    const basx = smart + '/document/cancel';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const GetUserDocumentsListWait = async(item)=>{

    const basx = smart + '/contracts/pending/kyc';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const docxGetPendingEsin = async(item)=>{

    const basx = smart + '/document/pending/esin';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const docxGetSummary = async(item)=>{

    const basx = smart + '/document/get/summary';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }

  export const GetUserFeatures = async(item)=>{
    
    const basx = smart + '/user/feat/get';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const SetUserFeatures = async(item)=>{
    
    const basx = smart + '/user/feat/set';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const ResetUserFeatures = async(item)=>{
    
    const basx = smart + '/user/feat/reset';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }


  export const uploadUserSignature = async(item)=>{
    
    const basx = smart + '/user/upload/sign';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_NMBR
    }
    const datx = item
  
    var result;
    try {
      result = await axios.post(basx, datx, {headers: head});
      // console.log (result)
      return ([200, 201].includes(result.status)) ? result.data : {data: false}
    } catch (error) {
      // console.log (error);
      return {data: false}
    }

  }



  



export const SetDocumentStamp = async()=>{}
  
  
 
  export const GetUserDocumentsList = async()=>{}
  
  export const CreateNewDocumentTest = async()=>{}
  export const CreateNewDocumentFile = async()=>{}
  export const CreateTaskDocumentBulk = async()=>{}
 
  export const CreateNewDocument = async()=>{}

  export const GetUserBalance = async()=>{}
  export const GetDocumentEsinStatus = async()=>{}
  export const GetVaultBalance = async()=>{}