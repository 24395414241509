// contracts
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import WebbLoader from "../webb/webb-loader";
import FormNeeded from "../webb/form-needed";
import WebbDividerSmall from "../webb/webb-divider-sm";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbModuleInfo from "../webb/webb-module-info";

import { UserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-user";

import { CreateNewDocument, SmartContractCreate } from "../../services/srvc-smart-realm";
import { AccountBalance, BaseAccountBalance } from "../../services/srvc-accounts-realm";

const list = (require("../../data/optn-documents-form.json")).data.filter(item => item.actv);

export default function SmartsNewInfoModule() {

  // const usrx = GetLocalUser();
  // const bxnz = GetLocalBusiness();

  // console.log(usrx)
  // console.log(bxnz)
  // const asset = UserForm() === 'in' ? usrx : bxnz;
  const asset = UserForm() === "in" ? GetLocalUser() : GetLocalBusiness();
  const history = useHistory();
  
  // console.log (asset)

  const [loading,setLoading]=useState(false);

  const [form, setForm] = useState(false);
  const [text, setText] = useState('');

  const [balance,setBalance] = useState()

  const [data, setData] = useState({    
    name: '',
    memo: '',
    nmbr: '24'+Date.now().toString(),
    form: '',
    stts: (new Date()).toISOString().substring(0,10),
    ents: (new Date()).toISOString().substring(0,10)
  })

  // form validation
  useEffect( () => {
    setForm(false)
    if (data.form !=='') 
      setForm(true);
  },[data]);

  useEffect(()=>{
    const fetchdata= async()=>{
      const result = await BaseAccountBalance({
        data: {user: asset.user, type:"base-account"}, 
        user: asset.user,
      })
      //console.log(result)
      var balance = parseFloat(result.data.balance.number)/1000000
      // console.log(balance)
      setBalance(balance)
    }
    fetchdata()
  },[asset.user])
  

  const handleChange = async(key, val) => {
    setForm(false);
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if(balance>100)
    {
      var payload = {
        data: {
            name: list.find(item=>item.nmbr===data.form).name,
            memo: data.memo!=''?data.memo:list.find(item=>item.nmbr===data.form).name,
            form: 'document',
            sort: '',
            nmbr: data.nmbr,
            stts: Date.parse(new Date(data.stts)),
            ents: Date.parse(new Date(data.ents+'T23:59:59.000')),
            user: asset.user ,
          },
          user: asset.user,
    }

    // console.log(payload)

    // api call
    const res = await SmartContractCreate(JSON.stringify(payload))
    
    // if (res) console.log (res)

    if (res.data) {
      setLoading(false);
    //   history.push(`/${asset.form.substring(0,2)}/documents/new/xxxx/${res.data.docn}`)
     history.push(`/${asset.form.substring(0,2)}/smart/view/${res.data.docn}`)
    }
    else setText('Error. Please try again')
    setLoading(false);
    }

    else{
      setText("Error. Insufficient Balance, Please Rechange your subsrciption Account")
      setLoading(false)
    }

    
  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* info */}
    <WebbDividerSmall/>
    <WebbModuleInfo data={{info: 'Please enter Document Details'}}/>
    <WebbDividerSmall />

    {/* data */}
    <form onSubmit={handleSubmit}>

      <div className="">

        <div className="form-group mb-3">
            <label className="form-label small">Document Type <FormNeeded/></label>
            <select 
              className="form-select rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.form}
              onChange={({ target }) => handleChange("form", target.value)}
            >
              <option value=''>Select Document Type</option>
              {list.map((item,i) => (
                <option key={i} value={item.nmbr}>{item.name}</option>
              ))}
            </select>
        </div>

          <div className="form-group mb-3 d-none">
            <label className="form-label small">Document Name <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.name}
              onChange={({ target }) => {handleChange("name", target.value);}}
              placeholder="Document Name">
            </input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">Description <FormNeeded/></label>
          <textarea 
              className="form-control rounded-none" 
              style={{fontSize:'0.9rem'}}
              rows="3"
              value={data.memo}
              onChange={({ target }) => {handleChange("memo", target.value);}}
              placeholder="Description">  
          </textarea>
        </div> 

        <div className="form-group mb-3">
            <label className="form-label small">File / Reference Number (Internal)</label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.nmbr}
              onChange={({ target }) => {handleChange("nmbr", target.value);}}
              placeholder="DOC123456">
            </input>
        </div>

        <div className="form-group mb-3">
            <label className="form-label small">Start Date</label>
            <input type="date" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.stts}
              onChange={({ target }) => {handleChange("stts", target.value);}}
              placeholder="">
            </input>
        </div>

        <div className="form-group mb-3">
            <label className="form-label small">End Date</label>
            <input type="date" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={data.ents}
              onChange={({ target }) => {handleChange("ents", target.value);}}
              placeholder="">
            </input>
        </div>



      </div>


      <div className="mb-3">
        <p className="small text-danger">{text}<span className="text-white">{'.'}</span></p>
      </div>


      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-primary back-color-main rounded-none`}
            disabled={!form}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>

    <WebbDividerMedium />
    <WebbDividerMedium />
    
  </>
  )
}