//main
import { useState } from "react";

import ContentFormatXA from "../content/webc/content-format-xa";

import WebbDivider from "../content/webb/webb-divider";
import WebbDividerSmall from "../content/webb/webb-divider-sm";
import WebbDividerMedium from "../content/webb/webb-divider-md";
import HeaderSectionLite from "../content/webb/head-section-lite";
import WebbDateTime from "../content/webb/webb-datetime";

import UserInfoModule from "../content/home/user-infocard";
import UserListModule from "../content/user/user-list";

import UserProfile from "../content/user/user-profile";
import UserDocs from "../content/user/user-docs";
import UserAccounts from "../content/user/user-accounts";
import UserPrivacy from "../content/user/user-privacy";
import UserReferrals from "../content/user/user-referrals";
import UserOffers from "../content/user/user-offers";

import HeadingModule from "../content/webb/head-module";
import ContractFeature from "../content/user/user-contracts-feat";

const list = [
  {name: 'Account Settings', item: <p>Please Select Settings</p>},
  {name: 'Profile', item: <UserProfile />},
  {name: 'Documents', item: <UserDocs />},
  {name: 'Accounts', item: <UserAccounts />},
  {name: 'Privacy', item: <UserPrivacy />},
  {name: 'Referrals', item: <UserReferrals />},
  {name: 'Offers', item: <UserOffers />},
  {name: 'Features', item:<ContractFeature/>}
]

export default function Settings() {

  const [sort, setSort] = useState('Profile');

  const handleSort = async(item) => {
    console.log('item: ', item)
    setSort(item);
  }

  return (
  <>
    <ContentFormatXA 

    sidebar=
    {{
      head: <> <strong className="text-color-tone">Settings</strong> </>,
      data: 
        <> 
          <UserInfoModule />
          <WebbDivider />
          <WebbDividerSmall />
          <UserListModule item={handleSort}/>
          
        </>,
      foot: <> <WebbDateTime /> </>,
    }}
    main=   
      {{      
        head: <><HeadingModule data={sort}/></>,
        data: list.find(x => x.name === sort).item,
        foot: <> </>,
      }}
    actionbar=
      {{      
        head: <> </>,
        data: <> </>,
        foot: <> </>
      }}    
    
    />
  </>
  )
}